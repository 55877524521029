<template>
  <div id="app">
    <formar-header />

    <router-view />

    <formar-footer />
  </div>
</template>
<script>
import formarHeader from "@/components/Header.vue";
import formarFooter from "@/components/Footer.vue";
export default {
  name: "app",
  components: {
    formarHeader,
    formarFooter,
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  created() {
    if (!window.sessionStorage.getItem("userData")) {
      // this.open()
    }
  },
  methods: {},
};
</script>

<style></style>
